<template>
    <div class='panel panel-default'>
        <div class='panel-heading'></div>
        <div class='row panel-body'>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Scheduled From</label>
                <DatePicker v-model="state.optionFields.scheduledStartDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Scheduled To</label>
                <DatePicker v-model="state.optionFields.scheduledEndDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Completed From</label>
                <DatePicker v-model="state.optionFields.completedStartDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Completed To</label>
                <DatePicker v-model="state.optionFields.completedEndDate.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" :key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Type</label>
                <select v-model="state.optionFields.type.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, type] in cache.assetCache.ASSET_TYPE" :key="id" :value="id">{{ type }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6'>
              <label>Asset Number</label><input type="text" class="form-control" v-model="state.optionFields.asset_id.val">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Frequency</label>
                <select class="form-control" v-model="state.optionFields.frequency.val" >
                    <option value="0">All</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="biannually">Biannually</option>
                    <option value="annually">Annually</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group form_grid">
                <label><input type=checkbox  v-model="state.optionFields.excludeExpiredTasks.val" :value="1" :false-value=null > Exclude expired tasks</label>
            </div>
        </div>
    </div>
</template>

<script>

    import { store } from "@/store/Report.store.js";
    import DatePicker from "../utils/DatePicker";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";
    import assetCache from "@/cache/asset.cache.js";

    export default {
        name: "Options",
        components: {
          DatePicker,
        },
        data() {
            return {
                state: store.state,
                cache: {
                    assetCache,
                    cdiLocationCache
                }
            }
        }
    }

</script>