<template>
    <div>
        <h1>Maintenance</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store";
    import Options from "./maintenance_opt"
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    import DateHelpers from "../../store/DateHelpers";
    export default {
        name: 'maintenance',
        components: {Options, Results, Fields, GroupBy},
        data() {
            return {
                optionFields: {
                    scheduledStartDate: {val: DateHelpers.getXMonthsAgo(3), qsField: '[dates][scheduled_date_from]'},
                    scheduledEndDate: {val: new Date().toLocaleDateString(), qsField: '[dates][scheduled_date_to]'},
                    completedStartDate: {val: '', qsField: '[dates][date_completed_from]'},
                    completedEndDate: {val: '', qsField: '[dates][date_completed_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][a.cdi_location_id]'},
                    type: {val: 0, qsField: '[numbers][a.asset_type]'},
                    frequency: {val: 0, qsField: '[strings][ams.frequency]'},
                    excludeExpiredTasks: {val: 1, qsField: '[isnot][amt.expired]'},
                    asset_id: {val: '', qsField: '[numbers][a.asset_id]'},
                },
                groupByFields: {
                    asset_id: {label: 'Asset', checked: true},
                    asset_type: {label: 'Type', checked: false}
                },
                dataFields: {
                    asset_id: {label: 'Asset ID', checked: false},
                    asset_label: {label: 'Asset Name', checked: true},
                    name: {label: 'Schedule Name', checked: true},
                    maintenance_schedule_id: {label: 'Schedule ID', checked: true},
                    scheduled_date: {label: 'Scheduled Date', checked: true},
                    date_completed: {label: 'Date Completed', checked: true},
                    user_id: {label: 'Completed By', checked: true},
                    was_late: {label: 'Late', checked: true},
                    task_expired: {label: 'Task Expired', checked: false},
                    maintenance_photos: {label: 'Photos', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Maintenance']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>